import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button, Card } from 'react-bootstrap'

// Defines the layout of every page blog post card.
const BlogCard = ({postData}) => {
  
  // Set the description text in a way that does not break NodeJS.
  const [snippet, setSnippet] = useState("")
  useEffect(() => {
    const parser = new DOMParser()
    let htmlDocument = parser.parseFromString(postData.content, "text/html")
    let descText = ""
    try {
      descText = htmlDocument.getElementsByClassName("medium-feed-snippet")[0].textContent
      setSnippet(descText)
    } catch (e) {
      descText = htmlDocument.getElementsByTagName("h4")[0].textContent
      setSnippet(descText)
    }
  }, [postData.content])

  return (
    <Card key={postData.id} style={{width:"320px", height:"240px", marginBottom:"8px", marginTop:"8px", background:"#f5efed"}}>
        <Card.Body style={{textAlign:"left"}}>
            <Card.Title>{postData.title}</Card.Title>
            <Card.Subtitle class="text-muted">Posted: {postData.date}</Card.Subtitle>
            <Card.Text>{snippet}</Card.Text>
        </Card.Body>
        <Card.Footer>
              <Button variant="outline-dark" href={postData.link}>Read more</Button>
        </Card.Footer>
    </Card>
  )
}

export default BlogCard