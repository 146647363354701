import * as React from 'react'
import { graphql } from 'gatsby'
import BaseLayout from '../components/base-layout'
import 'bootstrap/dist/css/bootstrap.min.css'
import BlogCard from '../components/blog-card'
import { Col, Row } from 'react-bootstrap'

// Blog front page; displays all blog entries in a 3x? table.
// TODO: Find alternative for using "align" in blog post row as it is not good practice.
const BlogPage = ({ data }) => {
  return (
    <BaseLayout pageTitle="Latest updates in the realm of TNNL, automation and coding | Blog" pageDescription="News from the world of TNNL, automation and coding, including business updates and tips to get the most out of your code and save time at work.">
      <Row>
        <Col>
          <h1 class="display-1" style={{textAlign:"center"}}><b>Blog</b></h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p class="lead" style={{textAlign:"center"}}><b>News from the world of TNNL, automation and coding.</b></p>
        </Col>
      </Row>
      <Row xs={1} lg={3} align="center">
        {data.allMediumPosts.nodes.map((node) => (
              <Col>
                <BlogCard postData={node}/>
              </Col>
          ))}
      </Row>
    </BaseLayout>
  )
}

// Query which obtains all blog posts to display.
export const query = graphql
    `query {
      allMediumPosts {
        nodes {
          title
          link
          date(formatString: "D MMMM, YYYY")
          content
          thumbnail
          id
        }
      }
    }`

export default BlogPage